header {
  width: 100%;
  height: 80px;
  background: #10101ea6;
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 99;

  .container {
    max-width: 1360px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      gap: 10px;

      img {
        height: 40px;

        &::selection {
          background: none;
        }
      }

      h3 {
        color: white;
        transition: 0.3s;
        font-size: 32px;

        &::selection {
          background: none;
        }
      }
    }

    .list {
      display: flex;
      align-items: center;
      gap: 30px;

      ul {
        display: flex;
        gap: 30px;
        list-style: none;

        a {
          cursor: pointer;
          position: relative;
          text-decoration: none;
          color: #fff6ff;
          font-size: 17px;

          &::before {
            content: "";
            width: 0%;
            height: 3px;
            background: $stroke-color;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: 0.2s;
            border-radius: 0 1em 1em 0;
          }

          &:hover {
            &::before {
              width: 98%;
            }
          }
        }
      }

      .menu {
        display: none;
        font-size: 30px;
        color: $main-color;
        cursor: pointer;
      }

      .lang {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 28px;

        img {
          width: 32px;
          cursor: pointer;
        }

        .badge {
          cursor: pointer;
          background: $main-color;
          color: $background;
          font-weight: 700;
          padding: 0 15px;
          border-radius: 0 1em 0 1em;
          transition: 0.3s;
          height: 28px;

          display: flex;
          align-items: center;
          gap: 5px;

          span::selection {
            background: transparent;
          }

          svg {
            margin-top: 1px;
            transform: rotate(0deg);
            transition: 0.3s;
          }

          &:active {
            transform: scale(0.95);
          }
        }

        ul {
          transition: 0.2s;
          position: absolute;
          top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          background: $main-color;
          width: 100%;
          height: 0px;
          border-radius: 1em 0 1em 0;
          color: $background;
          font-weight: 700;
          visibility: hidden;
          overflow: hidden;
          gap: 0;

          li {
            cursor: pointer;

            &:nth-child(1) {
              padding-top: 10px;
            }

            &:nth-child(2) {
              padding: 0 5px;
            }

            &:nth-child(3) {
              padding-bottom: 10px;
            }
          }

          &.active {
            height: 112px;
            visibility: visible;
          }
        }
      }
    }
  }
}

main {
  width: 100%;
  max-width: 1360px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .section {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  #home {
    width: 100%;
    background-image: url("../../public/images/hero/bg.svg");
    background-position: center 20px;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 220px 10px 70px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .title {
      width: 55%;
      display: flex;
      flex-direction: column;
      gap: 62px;

      &::selection {
        background: transparent;
      }

      h1 {
        color: #fff6ff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 82px;
        font-style: normal;
        font-weight: 700;
        line-height: 85.621px;
        letter-spacing: -1.639px;
        position: relative;

        .mern {
          background: linear-gradient(90deg, $stroke-color -100%, white 40%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

          &::selection {
            background: transparent;
          }

          span::selection {
            background: transparent;
          }

          #m {
            background: linear-gradient(90deg, white -50%, #47ff6620 40%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
          }

          #e {
            background: linear-gradient(90deg, white -50%, #ff544820 40%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
          }

          #r {
            background: linear-gradient(90deg, white -50%, #71aaff20 40%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
          }

          #n {
            background: linear-gradient(90deg, white -50%, #50ff5020 40%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
          }
        }

        span.and {
          color: $dark-color;

          &::selection {
            background: transparent;
          }
        }

        #knot {
          position: absolute;
          top: 55px;
          left: 375px;
          width: 120px;
          transform: rotate(20deg);
        }

        #balllight {
          width: 18px;
          opacity: 0.8;
        }

        .dev {
          position: relative;
          z-index: 1;

          background: linear-gradient(90deg, $stroke-color -100%, white 40%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

          &::selection {
            background: transparent;
          }
        }

        &::selection {
          background: transparent;
        }
      }

      .links {
        display: flex;
        align-items: center;
        gap: 30px;

        a {
          all: unset;
          height: 68px;
          background: $main-color;
          border-radius: 0.8em;
          text-align: center;
          font-size: 24px;
          cursor: pointer;
          transition: 0.3s;

          &:active {
            transform: scale(0.95);
          }

          span {
            line-height: 68px;
            padding: 0 30px;
            white-space: nowrap;
          }
        }

        .dot {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            transform: translate(-50%, -50%);
          }
        }

        p {
          color: #fff6ff;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 33.7px;
          letter-spacing: -0.641px;
          white-space: nowrap;
        }
      }
    }

    .image {
      width: 600px;
      height: 470px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      position: relative;
      overflow: hidden;
      border-radius: 2em 5em 0 5em;

      img {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(1.1);
        z-index: 9;
      }

      .top {
        width: 100%;
        height: 53%;
        display: flex;
        align-items: center;
        gap: 14px;

        .rectangle1 {
          width: 18%;
          height: 100%;
          background: $main-color;
          border-radius: 10em;
          opacity: 0.7;
        }

        .rectangle2 {
          width: 82%;
          height: 100%;
          background: $stroke-color;
          border-radius: 0 5em 0 5em;
          opacity: 0.5;
        }
      }

      .rectangle3 {
        width: 100%;
        height: 47%;
        background: $dark-color;
        border-radius: 0 5em 0 5em;
        opacity: 0.4;
      }
    }

    #cube {
      position: absolute;
      top: -132px;
      right: -400px;
      width: 400px;
      z-index: -100;
      transform: rotate(45deg);
    }

    #torus {
      position: absolute;
      top: 80vh;
      left: -290px;
      width: 350px;
      z-index: -100;
      transform: rotate(90deg);
    }
  }

  .section-two {
    width: 100%;
    padding: 170px 10px 70px;

    display: flex;
    justify-content: space-between;

    .about {
      width: 50%;
      position: relative;

      .blur {
        position: absolute;
        top: -70px;
        left: 0;
        z-index: -100;
      }

      .blur2 {
        position: absolute;
        top: 350px;
        right: -320px;
        z-index: -100;
      }

      p {
        color: #707186;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
        text-shadow: 0 0 5px black;
      }

      h1 {
        font-size: 43px;
        font-weight: 300;
        line-height: 54px;
        letter-spacing: -1.073px;
        margin-bottom: 29px;

        span {
          font-weight: 500;
        }
      }

      h2 {
        color: #707186;
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
      }
    }

    .skills {
      width: 50%;
      position: relative;

      img,
      h1,
      p {
        &::selection {
          background: transparent;
        }
      }

      img {
        transform: scale(1.1);
      }

      .light {
        position: absolute;
        top: -380px;
        right: -150px;
        z-index: -100;
      }

      .branch {
        position: absolute;
        top: -20px;
        right: -120px;
        z-index: -100;
      }

      .diosamule {
        position: absolute;
        top: 10px;
        right: -110px;
      }

      .skillbg {
        position: absolute;
        top: 100px;
        right: 10px;
      }

      .mouse {
        position: absolute;
        top: 60px;
        right: 360px;
      }

      h1 {
        position: absolute;
        top: 110px;
        right: 215px;
        color: #707186;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 27px;
        font-weight: 500;
        line-height: 40.625px;
        letter-spacing: -0.677px;
      }

      .skill {
        width: 41px;
        height: 41px;

        &.mongo {
          transform: scale(1.35);
          position: absolute;
          top: 170px;
          right: 310px;
        }

        &.express {
          transform: scale(1.24);
          position: absolute;
          top: 170px;
          right: 247px;
        }

        &.react {
          transform: scale(1.1);
          position: absolute;
          top: 170px;
          right: 187px;
        }

        &.node {
          transform: scale(1.05);
          position: absolute;
          top: 170px;
          right: 130px;
        }
      }

      .skillname {
        width: 41px;
        font-size: 10px;
        font-weight: 500;

        &.mongo {
          position: absolute;
          top: 215px;
          right: 312px;
          color: #6aa341;
        }

        &.express {
          position: absolute;
          top: 215px;
          right: 251.5px;
          color: #d17770;
        }

        &.react {
          position: absolute;
          top: 215px;
          right: 187px;
          color: #61dbfb;
        }

        &.node {
          position: absolute;
          top: 215px;
          right: 129px;
          color: #529e42;
        }
      }
    }
  }

  .section-three {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 100px;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;

    .education {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        color: #707186;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
      }

      .wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 30px;

        .edu {
          width: 55%;
          height: 80px;
          display: flex;
          gap: 15px;

          &:nth-child(even){
            width: 45%;
          }

          img {
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 10px #00000060;
          }

          .edu-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px 0;

            h1 {
              color: #d6d6d6;
              font-size: 20px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: -0.4px;
            }

            h2 {
              color: #d6d6d6;
              font-size: 18px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: -0.4px;
            }

            h3 {
              color: #d6d6d6;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.4px;
            }
          }
        }
      }
    }

    .experience {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        color: #707186;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
      }

      .wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 30px;

        .exp {
          width: 55%;
          height: 80px;
          display: flex;
          gap: 15px;

          &:nth-child(even){
            width: 45%;
          }

          img {
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 10px #00000060;
          }

          .exp-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 2px 0 h1 {
              color: #d6d6d6;
              font-size: 20px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: -0.4px;
            }

            h2 {
              color: #d6d6d6;
              font-size: 18px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: -0.4px;
            }

            h3 {
              color: #d6d6d6;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.4px;
            }
          }
        }
      }
    }
  }

  #skills {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 120px;
    margin-top: 40px;

    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .frontend {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (max-width: 900px) {
        width: 100%;

        p {
          text-align: center;
        }

        .wrapper {
          justify-content: center !important;
          margin-bottom: 70px;
        }
      }

      p {
        color: #707186;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
      }

      .wrapper {
        width: 100%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: 900px) {
          gap: 12px;
        }

        .skill {
          width: 72px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          transition: 0.15s;
          cursor: default;

          @media (max-width: 900px) {
            div svg {
              font-size: 40px !important;
            }

            span {
              font-size: 11px !important;
            }
          }

          &:hover {
            transform: scale(1.2);
            color: $main-color;
          }

          div svg {
            font-size: 48px;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
          }

          &.NextJS:hover {
            color: #000000
          }

          &.React:hover {
            color: #61DBFB
          }

          &.React.Query:hover {
            color: #FF4154
          }

          &.Redux.Toolkit:hover {
            color: #7549B9
          }

          &.Redux.Saga:hover {
            color: #89D96D
          }

          &.Zustand:hover {
            color: #F78139
          }

          &.MobX:hover {
            color: #D7570B
          }

          &.JavaScript:hover {
            color: #F7DF1E
          }

          &.TypeScript:hover {
            color: #007ACC
          }

          &.HTML:hover {
            color: #FF5325
          }

          &.CSS:hover {
            color: #264EE4
          }

          &.SASS:hover {
            color: #CD669A
          }

          &.Tailwind.CSS:hover {
            color: #38BDF8
          }

          &.Bootstrap:hover {
            color: #7111F5
          }

          &.Framer.Motion:hover {
            color: #E630BE
          }

          &.Material.UI:hover {
            color: #007FFF
          }

          &.Style.Component:hover {
            color: #BF4F74
          }

          &.Firebase:hover {
            color: #FFCC31
          }

          &.Git:hover {
            color: #F34F29
          }

          &.GitHub:hover {
            color: #171515
          }
        }
      }
    }

    .backend {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (max-width: 900px) {
        width: 100%;

        p {
          text-align: center;
        }

        .wrapper {
          justify-content: center !important;
        }
      }

      p {
        color: #707186;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
      }

      .wrapper {
        width: 100%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: 900px) {
          gap: 12px;
        }

        .skill {
          width: 72px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          transition: 0.15s;

          @media (max-width: 900px) {
            div svg {
              font-size: 40px !important;
            }

            span {
              font-size: 11px !important;
            }
          }

          &:hover {
            transform: scale(1.2);
            color: $main-color;
          }

          div svg {
            font-size: 48px;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
          }

          &.NodeJS:hover {
            color: #8CC03E
          }

          &.ExpressJS:hover {
            color: #828282
          }

          &.NestJS:hover {
            color: #E0234E
          }

          &.MongoDB:hover {
            color: #4FAA41
          }

          &.PostgreSQL:hover {
            color: #336791
          }

          &.Prisma:hover {
            color: #0C344B
          }

          &.SocketIo:hover {
            color: #010101
          }

          &.Axios:hover {
            color: #5A24E5
          }

          &.Vercel:hover {
            color: #000000
          }

          &.Docker:hover {
            color: #1D97EE
          }

          &.Nodemon:hover {
            color: #2EDF83
          }

          &.NodeTS:hover {
            color: #2261A8
          }

          &.JWT:hover {
            color: #FB015B
          }
        }
      }
    }
  }

  .section-four {
    width: 100%;
    padding: 135px 10px 0;
    margin-bottom: 400px;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 40px;

    #pill {
      transform: rotate(180deg);
      position: absolute;
      top: -280px;
      right: -250px;
      width: 300px;
      z-index: -100;
    }

    #cubo {
      transform: rotate(-100deg);
      position: absolute;
      bottom: -280px;
      left: -250px;
      width: 300px;
      z-index: -100;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      .portfolio {
        color: #707186;
        font-size: 24px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.401px;
        text-shadow: 0 0 5px black;
      }

      hr {
        width: 100%;
        border-color: #707186;
      }

      .all-project {
        font-size: 20px;
        line-height: 20px;

        display: flex;
        align-items: center;
        justify-content: end;

        color: #707186;
        cursor: pointer;

        span {
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }

    .projects {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 50px;

      .project {
        width: 100%;
        height: 400px;
        display: flex;
        overflow: hidden;
        position: relative;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        img {
          height: 100%;
        }

        .content {
          width: 100%;
          padding: 25px 25px 55px 25px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: 700;
            color: $main-color;
            margin-bottom: 20px;
          }

          h1 {
            font-size: 30px;
            margin: 20px 0;
            position: relative;
            letter-spacing: 1px;

            &::before {
              content: "";
              position: absolute;
              bottom: -13px;
              left: 50%;
              width: 80%;
              height: 4px;
              transform: translateX(-50%);
              border-radius: 2em;
              background: $main-color;
              box-shadow: 0 -8px 20px 1px $main-color;
            }
          }

          h3 {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            max-width: 550;
            margin-bottom: 24px;

            span {
              color: $main-color;
              font-weight: 600;
            }
          }

          .prolang {
            display: flex;
            align-items: center;
            gap: 16px;

            svg {
              font-size: 24px;

              color: $main-color;
            }
          }

          .link {
            margin-top: 30px;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 40px;

            a {
              padding: 8px 20px;
              border-radius: 0.4em;
              text-decoration: none;
              background: #322d41;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              max-width: 200px;
              color: white;
              outline: none;

              transition: 0.2s;

              &:hover,
              &:focus {
                transform: translateY(-4px);
                background: $main-color;
              }
            }
          }
        }
      }
    }
  }
}

footer {
  max-width: 95%;
  background: #191927;
  margin: 0 auto;
  border-radius: 3em 3em 0 0;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px 5px 0px inset;

  .container {
    max-width: 1360px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    padding: 10px 10px 0;
    position: relative;

    .LetsWork {
      position: absolute;
      top: -150px;
      right: 0;
      color: #fff6ff;
      text-align: right;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 61.346px;
      letter-spacing: -1.5px;
    }

    .footer-top {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px 0;

      .contacts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contact {
          display: flex;
          align-items: center;
          gap: 5px;

          &.calllock {
            display: flex;
            align-items: center;
            gap: 30px;
          }

          svg {
            font-size: 18px;
          }

          span {
            font-size: 17px;
          }
        }
      }
    }

    hr {
      border-color: #707186;
    }

    .footer-bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .social {
        display: flex;
        align-items: center;
        gap: 20px;

        div {
          display: flex;
          gap: 20px;

          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 5px;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
              transform: scale(1.2);

              svg,
              span {
                color: $main-color;
              }
            }

            svg {
              font-size: 22px;
              color: #fff6ff;
              transition: 0.2s;
              cursor: pointer;
            }
          }
        }
      }

      .ooo p {
        font-size: 18px;
        font-weight: 400;
        color: #fff6ff;
        cursor: default;

        a {
          text-decoration: none;
          color: white;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ErrorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  padding: 0 50px;

  .error {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      text-align: center;
      font-size: 22px;
      margin-top: 30px;
    }

    .subtitle {
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
    }

    .extra {
      display: flex;
      justify-content: center;

      button {
        padding: 0 20px;
        border-radius: 0.5em;
        background: $main-color;
        color: #ece4ec;
        font-weight: 600;
        border: none;
        outline: none;

        align-items: center;
        appearance: none;
        border-width: 0;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
          rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #52456e 0 -3px 0 inset;
        cursor: pointer;
        display: inline-flex;
        font-family: "JetBrains Mono", monospace;
        height: 48px;
        justify-content: center;
        line-height: 1;
        list-style: none;
        overflow: hidden;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow 0.15s, transform 0.15s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        will-change: box-shadow, transform;
        font-size: 16px;

        &:focus {
          box-shadow: #52456e 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #52456e 0 -3px 0 inset;
        }

        &:hover {
          box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #52456e 0 -3px 0 inset;
          transform: translateY(-2px);
        }

        &:active {
          box-shadow: #52456e 0 3px 7px inset;
          transform: translateY(2px);
        }

        span {
          letter-spacing: 0.5px;
        }
      }
    }
  }
}

.rodal {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100vw;
  height: 100vh;
  animation-duration: 250ms !important;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.3);

  .rodal-zoom-enter {
    animation: rodal-slideLeft-enter !important;
  }

  .rodal-zoom-leave {
    animation: rodal-slideLeft-leave !important;
  }

  .rodal-close {
    display: none;
  }

  .rodal-dialog {
    width: 300px !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background: #10101e;
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    animation-duration: 250ms !important;
    box-shadow: 0 0 20px #00000050;

    #header {
      width: 100%;
      transition: all 0.5s;
      padding: 0 15px;

      .profile {
        img {
          margin: 15px auto;
          display: block;
          width: 140px;
          border: 7px solid #2c2f3f;
        }

        h1 {
          font-size: 28px;
          margin: 0;
          padding: 0;
          font-weight: 600;
          -moz-text-align-last: center;
          text-align-last: center;
          font-family: "Poppins", sans-serif;

          a,
          a:hover {
            color: #fff;
            text-decoration: none;
          }
        }

        .social-links a {
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #212431;
          color: #fff;
          margin-right: 6px;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          transition: 0.3s;

          &:hover {
            background: $main-color;
            color: #10101e;
            text-decoration: none;
          }
        }
      }

      .nav-menu {
        padding: 30px 0 0 0;
      }

      .nav-menu * {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      .nav-menu>ul>li {
        position: relative;
        white-space: nowrap;
      }

      .nav-menu a,
      .nav-menu a:focus {
        display: flex;
        align-items: center;
        color: #a8a9b4;
        padding: 12px 20px;
        margin-bottom: 8px;
        transition: 0.3s;
        font-size: 21px;
        cursor: pointer;

        svg,
        .list {
          cursor: pointer;
        }
      }

      .nav-menu a i,
      .nav-menu a:focus i {
        font-size: 24px;
        padding-right: 8px;
        color: #6f7180;
      }

      .nav-menu a:hover,
      .nav-menu .active,
      .nav-menu .active:focus,
      .nav-menu li:hover>a {
        text-decoration: none;
        color: #fff;
      }

      .nav-menu a:hover i,
      .nav-menu .active i,
      .nav-menu .active:focus i,
      .nav-menu li:hover>a i {
        color: #149ddd;
      }
    }

    .mobile-nav-toggle {
      position: fixed;
      right: 15px;
      top: 15px;
      z-index: 9998;
      border: 0;
      font-size: 24px;
      transition: all 0.4s;
      outline: none !important;
      background-color: #149ddd;
      color: #fff;
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      border-radius: 50px;
      cursor: pointer;
    }

    .mobile-nav-active {
      overflow: hidden;
    }

    .mobile-nav-active #header {
      left: 0;
    }
  }
}

#upper {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 100;
  background: $stroke-color;
  padding: 8px;
  border-radius: 50%;
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0 10px $stroke-color;

  svg {
    font-size: 24px;
    margin: 5px 5px 0 5px;
    opacity: 80%;
  }

  &:hover {
    transform: scale(1.2);
  }

  &.non-active {
    transform: translateY(40px);
    visibility: hidden;
    opacity: 0;

    &:hover {
      transform: scale(1.2) translateY(40px);
    }
  }

  &.active {
    transform: translateY(-5px);

    &:hover {
      transform: scale(1.2) translateY(-5px);
    }
  }
}