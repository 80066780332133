@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/dejavu-sans-mono");

$background: #10101e;
$main-color: #5a547c;
$dark-color: #333047;
$stroke-color: #423e5c;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  background-color: #10101e;
  color: #fff6ff;
}

#root{
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-thumb {
  background: #333047;
  border-radius: 10em;
}
::-webkit-scrollbar-track {
  background: transparent;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.rounded-circle {
  border-radius: 50%;
}

.text-center {
  text-align: center;
}

.mt-3 {
  margin-top: 5%;
}

.gap-3 {
  gap: 10px;
}

.justify-center {
  justify-content: center;
}

#navbar ul a {
  display: flex;
  align-items: center;
  gap: 5px;
}

::selection{
  background: #423e5cab;
  text-shadow: 0 0 10px black;
}

img::selection{
  background: transparent;
}


@import './style.scss';
@import './media.scss';