@media (max-width: 1500px) {
  header .container {
    max-width: 1300px !important;
  }
  main {
    max-width: 1300px !important;

    .section-four .projects .project {
      height: 425px !important;
    }
  }
  footer .container {
    max-width: 1300px !important;
  }
}

@media (max-width: 1400px) {
  header .container {
    max-width: 1200px !important;
  }
  main {
    max-width: 1200px !important;

    .section-four {
      .portfolio {
        font-size: 48px;
      }
      .projects .project {
        height: 400px !important;
      }
    }
  }
  footer .container {
    max-width: 1200px !important;
  }
}

@media (max-width: 1300px) {
  header .container {
    max-width: 1100px !important;
  }
  main {
    max-width: 1100px !important;

    #home {
      padding-top: 200px !important;

      #cube {
        width: 380px;
        right: -380px;
        top: -132px;
      }

      #torus {
        width: 330px;
        left: -280px;
        top: 640px;
      }

      .title {
        h1 {
          font-size: 72px;
          line-height: 77px;

          #knot {
            width: 110px;
            left: 330px;
            top: 50px;
          }
        }
        .links {
          gap: 20px;
          a {
            height: 61.2px;

            span {
              line-height: 61.2px !important;
              padding: 0 27px;
            }
          }
          p {
            font-size: 24px;
          }
        }
      }
      .image {
        width: 500px !important;
        height: 390px !important;
      }
    }
    .section-two {
      .about h1 {
        font-size: 40px;
        line-height: 50px;
      }
    }
    .section-four {
      #pill {
        top: -270px;
        right: -240px;
        width: 280px;
      }

      #cubo {
        bottom: -270px;
        left: -240px;
        width: 280px;
      }

      .portfolio {
        font-size: 44px;
      }
      .all-project {
        font-size: 20px;
      }
      .projects .project {
        height: 360px !important;
      }
    }
  }
  footer .container {
    max-width: 1100px !important;

    .LetsWork {
      font-size: 38px;
    }
  }
}

@media (max-width: 1200px) {
  header .container {
    max-width: 1000px !important;
  }
  main {
    max-width: 1000px !important;

    #home {
      padding-top: 175px !important;
      z-index: 10;

      #cube {
        width: 350px;
        right: -350px;
        top: -140px;
      }

      #torus {
        width: 300px;
        left: -220px;
        top: 500px;
      }

      .title {
        h1 {
          font-size: 64px;
          line-height: 64px;

          #knot {
            width: 90px;
            left: 295px;
            top: 42px;
          }
        }
        .links {
          gap: 18px;

          a {
            height: 51px;
            border-radius: 0.5em;

            span {
              font-size: 20px;
              line-height: 51px !important;
              padding: 0 24px;
            }
          }

          .dot img {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 22px;
          }
        }
      }
      .image {
        transform: scale(0.9) !important;
        width: 470px !important;
        height: 366px !important;
      }
    }
    .section-two {
      .about {
        h1 {
          font-size: 36px;
          line-height: 44px;
        }
        h2 {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .skills {
        transform: scale(0.94);
      }
    }
    .section-three {
      margin-bottom: 120px !important;
      .education,
      .experience {
        .edu,
        .exp {
          .edu-title,
          .exp-title {
            h1 {
              font-size: 19px;
            }
            h2 {
              font-size: 17px;
            }
            h3 {
              font-size: 15px;
            }
          }
        }
      }
    }
    .section-four {
      #pill {
        top: -240px;
        right: -210px;
        width: 240px;
      }

      #cubo {
        bottom: -260px;
        left: -230px;
        width: 260px;
      }

      .portfolio {
        font-size: 44px;
      }
      .all-project {
        font-size: 20px;
      }

      .projects .project {
        height: 325px !important;

        .content {
          transform: scale(0.95);
          p {
            margin-bottom: 15px;
          }
          h1 {
            margin: 7px 0 35px;
          }
          .prolang {
            margin-bottom: 20px;
          }

          .link {
            margin: 0 !important;
          }
          a {
            margin-top: 30px;

            &:hover,
            &:focus {
              margin-top: 27px;
            }
          }
        }
      }
    }
  }
  footer .container {
    max-width: 1000px !important;

    .LetsWork {
      top: -140px;
      font-size: 34px;
    }
  }
}

@media (max-width: 1100px) {
  header .container {
    max-width: 900px !important;
  }
  main {
    max-width: 900px !important;

    #home {
      padding-top: 150px !important;

      #cube {
        width: 300px;
        right: -300px;
        top: -130px;
      }

      #torus {
        width: 250px;
        left: -220px;
        top: 450px;
      }

      .title {
        gap: 40px;
        h1 {
          font-size: 58px;
          line-height: 58px;

          #knot {
            width: 80px;
            left: 270px;
            top: 35px;
          }
        }

        .links {
          gap: 15px;

          a {
            height: 40px;
            border-radius: 0.4em;

            span {
              font-size: 17px;
              line-height: 40px !important;
              padding: 0 20px;
            }
          }

          p {
            font-size: 21px;
          }
        }
      }
      .image {
        width: 430px !important;
        height: 330px !important;
      }
    }
    .section-two {
      position: relative;
      padding: 115px 10px 70px;

      .about {
        h1 {
          font-size: 32px;
          line-height: 40px;
        }
        h2 {
          font-size: 18px;
          line-height: 28px;
        }
      }
      .skills {
        transform: scale(0.8);
        position: absolute;
        right: -10px;
      }
    }
    .section-three {
      margin-bottom: 80px !important;

      .education,
      .experience {
        .edu,
        .exp {
          height: 70px;
          .edu-title,
          .exp-title {
            h1 {
              font-size: 16px;
            }
            h2 {
              font-size: 14px;
            }
            h3 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .section-four {
      margin-bottom: 300px !important;

      #pill {
        top: -180px;
        right: -180px;
        width: 200px;
      }

      #cubo {
        bottom: -200px;
        left: -200px;
        width: 220px;
      }

      .portfolio {
        font-size: 38px;
        line-height: 50px;

        img {
          height: 50px;
        }
      }
      .all-project {
        font-size: 20px;
      }

      .projects .project {
        height: 300px !important;

        .content {
          p {
            margin-bottom: 10px;
            font-size: 12px;
          }
          h1 {
            margin: 5px 0 30px;
            font-size: 28px;
          }
          .prolang {
            margin-bottom: 16px;
          }
          h3 {
            font-size: 14px;
          }
          a {
            margin-top: 25px;
            padding: 7px 18px;
            max-width: 90px;
            font-size: 14px;

            &:hover,
            &:focus {
              margin-top: 22px;
            }
          }
        }
      }
    }
  }
  footer .container {
    max-width: 900px !important;

    .LetsWork {
      top: -120px;
      font-size: 32px;
      line-height: 50px;
    }
  }
}

@media (max-width: 1000px) {
  header .container {
    max-width: 800px !important;
  }
  main {
    max-width: 800px !important;

    #home {
      padding-top: 145px !important;

      #cube {
        right: -270px;
        top: -110px;
      }

      #torus {
        left: -240px;
        top: 400px;
      }

      .title {
        gap: 40px;
        h1 {
          font-size: 52px;
          line-height: 54px;

          #knot {
            width: 70px;
            left: 242px;
            top: 35px;
          }
        }

        .links {
          gap: 10px;

          a {
            height: 35px;

            span {
              font-size: 15px;
              line-height: 35px !important;
              padding: 0 18px;
            }
          }

          .dot img {
            width: 18px;
            height: 18px;
          }

          p {
            font-size: 19px;
          }
        }
      }
      .image {
        width: 390px !important;
        height: 300px !important;
      }
    }
    .section-two {
      position: relative;
      .about {
        h1 {
          font-size: 28px;
          line-height: 36px;
        }
        h2 {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .skills {
        transform: scale(0.7);
        position: absolute;
        right: -35px;
      }
    }
    .section-three {
      margin-bottom: 80px !important;

      .education,
      .experience {
        .edu,
        .exp {
          height: 60px;
          .edu-title,
          .exp-title {
            h1 {
              font-size: 15px;
            }
            h2 {
              font-size: 13px;
            }
            h3 {
              font-size: 11px;
            }
          }
        }
      }
    }
    .section-four {
      margin-bottom: 300px !important;

      .portfolio {
        font-size: 34px;
        line-height: 50px;

        img {
          height: 50px;
        }
      }
      .all-project {
        font-size: 18px;
      }
      .projects .project {
        height: 260px !important;

        .content {
          padding: 25px;
          transform: scale(0.9);

          p {
            margin-bottom: 5px;
            font-size: 12px;
          }
          h1 {
            margin: 4px 0 25px;
            font-size: 28px;

            &::before {
              bottom: -9px;
            }
          }
          .prolang {
            margin-bottom: 15px;
            svg {
              font-size: 22px !important;
            }
          }
          h3 {
            font-size: 14px;
          }
          a {
            margin-top: 20px;
            padding: 7px 18px;
            max-width: 90px;
            font-size: 14px;

            &:hover,
            &:focus {
              margin-top: 18px;
            }
          }
        }
      }
    }
  }
  footer .container {
    max-width: 800px !important;

    .LetsWork {
      top: -110px;
      font-size: 30px;
      line-height: 45px;
    }
  }
}

@media (max-width: 900px) {
  header .container {
    max-width: 700px !important;

    .list {
      gap: 20px !important;

      .listUl {
        display: none !important;
      }

      .menu {
        display: block !important;
      }
    }
  }

  main {
    max-width: 700px !important;

    #home {
      flex-direction: column;
      gap: 40px;

      #cube {
        width: 320px;
        right: -280px;
        top: -100px;
      }

      #torus {
        width: 300px;
        left: -280px;
        top: 1060px;
      }

      .title {
        width: 100% !important;
        align-items: center;

        h1 {
          font-size: 90px !important;
          line-height: 100px;
          text-align: center;

          #knot {
            width: 140px;
            left: 440px;
            top: 60px;
          }
        }
        .links {
          justify-content: space-between;
          gap: 40px;
          max-width: 700px;

          a {
            height: 58px !important;

            span {
              font-size: 24px;
              line-height: 59px !important;
              padding: 0 40px;
            }
          }

          .dot img {
            width: 28px;
            height: 28px;
          }

          p {
            font-size: 36px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 530px !important;
      }
    }

    .section-two {
      flex-direction: column;
      gap: 100px;
      padding: 170px 10px 100px;

      .about {
        width: 100%;
        height: auto;

        p {
          font-size: 22px;
          text-align: center;
          padding-bottom: 10px;
        }

        h1 {
          font-size: 50px;
          line-height: 55px;
          text-align: center;
        }
        h2 {
          font-size: 36px;
          line-height: 40px;
          text-align: center;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(1.2) translateX(13%);

        .light {
          top: -400px;
          right: -180px;
        }
      }
    }

    .section-three {
      flex-direction: column;
      gap: 80px;

      .education {
        width: 100% !important;

        p {
          font-size: 22px;
          text-align: center;
        }

        .edu {
          flex-direction: column;
          align-items: center;
          height: auto !important;

          img {
            height: 140px;
          }

          .edu-title {
            gap: 10px;

            h1 {
              text-align: center;
              font-size: 28px;
            }
            h2 {
              text-align: center;
              font-size: 24px;
            }
            h3 {
              text-align: center;
              font-size: 20px;
            }
          }
        }
      }

      .experience {
        width: 100% !important;

        p {
          font-size: 22px;
          text-align: center;
        }

        .exp {
          flex-direction: column;
          align-items: center;
          height: auto !important;

          img {
            height: 140px;
          }

          .exp-title {
            gap: 10px;

            h1 {
              text-align: center;
              font-size: 28px;
            }
            h2 {
              text-align: center;
              font-size: 24px;
            }
            h3 {
              text-align: center;
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-four {
      gap: 40px;

      .portfolio {
        font-size: 58px;
      }

      .all-project {
        font-size: 28px;
      }

      .projects .project {
        height: auto !important;
        flex-direction: column !important;

        img {
          height: auto;
          width: 100%;
        }

        .content {
          padding: 5px 10px 10px;

          p {
            margin-bottom: 14px;
            font-size: 14px;
          }
          h1 {
            margin: 10px 0 40px;
            font-size: 30px;

            &::before {
              bottom: -13px;
            }
          }
          .prolang {
            margin-bottom: 25px;
            svg {
              font-size: 24px !important;
            }
          }
          h3 {
            font-size: 16px;
          }
          a {
            margin-top: 30px;
            padding: 10px 20px;
            max-width: 100px;
            font-size: 16px;

            &:hover,
            &:focus {
              margin-top: 27px;
            }
          }
        }
      }
    }
  }

  footer .container {
    max-width: 700px !important;
    gap: 20px;

    .LetsWork {
      top: -120px;
      font-size: 42px;
      line-height: 52px;
    }

    .footer-top {
      padding: 20px 0 10px;

      .contacts {
        flex-direction: column;
        gap: 20px;

        .contact {
          &.calllock {
            width: auto !important;
          }
          svg {
            font-size: 27px;
          }
          span {
            font-size: 26px;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px;

      .social {
        div {
          a {
            svg {
              font-size: 27px !important;
            }
            span {
              font-size: 26px !important;
            }
          }
        }
      }

      .ooo p {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 800px) {
  header .container {
    max-width: 650px !important;
  }

  main {
    max-width: 650px !important;

    #home {
      gap: 40px;

      #torus {
        width: 300px;
        left: -280px;
        top: 1000px;
      }

      .title {
        h1 {
          font-size: 80px !important;
          line-height: 90px;
          text-align: center;

          #knot {
            width: 120px;
            left: 390px;
            top: 60px;
          }
        }

        .links {
          gap: 35px;
          a {
            span {
              font-size: 24px;
              line-height: auto !important;
              padding: 0 30px;
            }
          }

          p {
            font-size: 34px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 490px !important;
      }
    }

    .section-two {
      .about {
        h1 {
          font-size: 46px;
          line-height: 54px;
        }
        h2 {
          font-size: 32px;
          line-height: 40px;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(1.1) translateX(18%);
      }
    }

    .section-three {
      gap: 70px;
      .education {
        .edu {
          img {
            height: 130px;
          }
          .edu-title {
            h1 {
              font-size: 28px;
            }
            h2 {
              font-size: 26px;
            }
            h3 {
              font-size: 24px;
            }
          }
        }
      }
      .experience {
        .exp {
          img {
            height: 130px;
          }
          .exp-title {
            h1 {
              font-size: 28px;
            }
            h2 {
              font-size: 26px;
            }
            h3 {
              font-size: 24px;
            }
          }
        }
      }
    }

    .section-four {
      gap: 35px;

      #pill {
        top: -240px;
        right: -210px;
        width: 240px;
      }

      #cubo {
        bottom: -200px;
        left: -200px;
        width: 260px;
      }

      .portfolio {
        font-size: 52px;
      }

      .all-project {
        font-size: 26px;
      }
    }
  }

  footer .container {
    max-width: 650px !important;

    .LetsWork {
      top: -110px;
      font-size: 38px;
      line-height: 48px;
    }

    .footer-top {
      padding: 20px 0 10px;

      .contacts {
        flex-direction: column;
        gap: 20px;

        .contact {
          &.calllock {
            width: auto !important;
          }
          svg {
            font-size: 25px;
          }
          span {
            font-size: 24px;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px;

      .social {
        div {
          a {
            svg {
              font-size: 25px !important;
            }
            span {
              font-size: 24px !important;
            }
          }
        }
      }

      .ooo p {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 700px) {
  header .container {
    max-width: 600px !important;
  }

  main {
    max-width: 600px !important;

    #home {
      gap: 40px;

      #cube {
        width: 300px;
        right: -250px;
        top: -100px;
      }

      #torus {
        width: 300px;
        left: -240px;
        top: 960px;
      }

      .title {
        h1 {
          font-size: 75px !important;
          line-height: 85px;

          #knot {
            width: 115px;
            left: 365px;
            top: 50px;
          }
        }
        .links {
          gap: 30px;

          a {
            span {
              font-size: 24px;
            }
          }

          p {
            font-size: 31px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 450px !important;
      }
    }

    .section-two {
      gap: 70px;

      .about {
        h1 {
          font-size: 40px;
          line-height: 48px;
        }
        h2 {
          font-size: 28px;
          line-height: 36px;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(1) translateX(25%);
      }
    }

    .section-three {
      gap: 70px;
      .education {
        .edu {
          img {
            height: 120px;
          }
          .edu-title {
            h1 {
              font-size: 26px;
            }
            h2 {
              font-size: 24px;
            }
            h3 {
              font-size: 22px;
            }
          }
        }
      }
      .experience {
        .exp {
          img {
            height: 120px;
          }
          .exp-title {
            h1 {
              font-size: 26px;
            }
            h2 {
              font-size: 24px;
            }
            h3 {
              font-size: 22px;
            }
          }
        }
      }
    }

    .section-four {
      gap: 30px;

      .portfolio {
        font-size: 48px;
      }

      .all-project {
        font-size: 24px;
      }
    }
  }

  footer .container {
    max-width: 600px !important;

    .LetsWork {
      top: -105px;
      right: 20px;
      font-size: 35px;
      line-height: 45px;
    }

    .footer-top {
      padding: 20px 0 10px;

      .contacts {
        flex-direction: column;
        gap: 20px;

        .contact {
          &.calllock {
            width: auto !important;
          }
          svg {
            font-size: 21px;
          }
          span {
            font-size: 20px;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px;

      .social {
        div {
          a {
            svg {
              font-size: 21px !important;
            }
            span {
              font-size: 20px !important;
            }
          }
        }
      }

      .ooo p {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  header .container {
    max-width: 500px !important;
  }

  main {
    max-width: 500px !important;

    #home {
      gap: 40px;

      #torus {
        width: 260px;
        left: -200px;
        top: 850px;
      }

      .title {
        h1 {
          font-size: 64px !important;
          line-height: 72px;

          #knot {
            width: 100px;
            left: 310px;
            top: 44px;
          }
        }
        .links {
          gap: 25px;

          a {
            height: 50px !important;
            span {
              font-size: 23px;
              line-height: 50px !important;
              padding: 0 25px;
            }
          }

          p {
            font-size: 25px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 370px !important;
        transform: scale(1) !important;
      }
    }

    .section-two {
      gap: 70px;

      .about {
        h1 {
          font-size: 36px;
          line-height: 44px;
        }
        h2 {
          font-size: 26px;
          line-height: 34px;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(0.88) translateX(37%);
      }
    }

    .section-three {
      gap: 70px;
      .education {
        .edu {
          img {
            height: 110px;
          }
          .edu-title {
            h1 {
              font-size: 24px;
            }
            h2 {
              font-size: 22px;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
      .experience {
        .exp {
          img {
            height: 110px;
          }
          .exp-title {
            h1 {
              font-size: 24px;
            }
            h2 {
              font-size: 22px;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-four {
      gap: 30px;

      .portfolio {
        font-size: 42px;
      }

      .all-project {
        font-size: 22px;
      }
    }
  }

  footer .container {
    max-width: 500px !important;

    .LetsWork {
      top: -100px;
      right: 20px;
      font-size: 32px;
      line-height: 42px;
    }

    .footer-top {
      padding: 20px 0 10px;

      .contacts {
        flex-direction: column;
        gap: 20px;

        .contact {
          &.calllock {
            width: auto !important;
          }
          svg {
            font-size: 19px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px;

      .social {
        div {
          a {
            svg {
              font-size: 19px !important;
            }
            span {
              font-size: 18px !important;
            }
          }
        }
      }

      .ooo p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 500px) {
  .rodal-dialog {
    #header {
      .profile {
        img {
          width: 120px !important;
        }
        h1 {
          font-size: 24px !important;
        }
      }
      .social-links a {
        width: 36px !important;
        height: 35px !important;
        font-size: 18px !important;
        margin-right: 4px !important;
      }

      .nav-menu a,
      .nav-menu a:focus {
        font-size: 18px !important;
      }
    }
  }

  header {
    transform: scale(0.95);
    border-radius: 3em;
    overflow: hidden;
    height: 70px;

    .container {
      max-width: 400px !important;
    }
  }

  main {
    max-width: 400px !important;

    #home {
      gap: 40px;

      #cube {
        top: -100px;
        right: -240px;
        width: 300px;
      }

      #torus {
        width: 260px;
        left: -200px;
        top: 750px;
      }

      .title {
        h1 {
          font-size: 52px !important;
          line-height: 64px;

          #knot {
            width: 85px;
            left: 252px;
            top: 40px;
          }
        }
        .links {
          a {
            border-radius: 0.6em;

            span {
              font-size: 20px;
              line-height: 50px !important;
            }
          }

          p {
            font-size: 19px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 305px !important;
        border-radius: 2em 4em 0 4em !important;

        .rectangle2 {
          border-radius: 0 4em 0 4em !important;
        }
        .rectangle3 {
          border-radius: 0 4em 0 4em !important;
        }
      }
    }

    .section-two {
      padding: 140px 10px 100px;
      gap: 0px;

      .about {
        h1 {
          font-size: 29px;
          line-height: 40px;
        }
        h2 {
          font-size: 21px;
          line-height: 34px;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(0.7) translateX(59%);
      }
    }

    .section-three {
      gap: 70px;
      .education {
        .edu {
          img {
            height: 100px;
          }
          .edu-title {
            h1 {
              font-size: 24px;
            }
            h2 {
              font-size: 22px;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
      .experience {
        .exp {
          img {
            height: 100px;
          }
          .exp-title {
            h1 {
              font-size: 24px;
            }
            h2 {
              font-size: 22px;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-four {
      gap: 20px;

      .portfolio {
        font-size: 36px;
      }

      .all-project {
        font-size: 18px;
      }
    }
  }

  footer .container {
    max-width: 400px !important;
    gap: 15px !important;

    .LetsWork {
      top: -100px;
      right: 10px;
      font-size: 28px;
      line-height: 38px;
    }

    .footer-top {
      padding: 20px 0 10px;

      .contacts {
        flex-direction: column;
        gap: 20px;

        .calllock {
          flex-direction: column;
          gap: 20px !important;
          width: auto !important;
        }

        .contact {
          &.calllock {
            width: auto !important;
          }
          svg {
            font-size: 21px;
          }
          span {
            font-size: 20px;
          }
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px;

      .social {
        gap: 25px !important;

        div {
          gap: 20px !important;

          a {
            gap: 5px !important;

            svg {
              font-size: 21px !important;
            }
            span {
              font-size: 20px !important;
            }
          }
        }
      }

      .ooo p {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 400px) {
  header .container {
    max-width: 350px !important;
  }

  main {
    max-width: 350px !important;

    #home {
      padding-top: 125px !important;
      gap: 30px;

      #cube {
        top: -110px;
        right: -230px;
        width: 300px;
      }

      #torus {
        width: 240px;
        left: -180px;
        top: 630px;
      }

      .title {
        gap: 25px;

        h1 {
          font-size: 46px !important;
          line-height: 56px;

          #knot {
            width: 80px;
            left: 220px;
            top: 30px;
          }
        }
        .links {
          gap: 10px;

          a {
            border-radius: 0.3em;
            height: 34px !important;
            padding-bottom: 1px;

            span {
              font-size: 16px;
              line-height: 34px !important;
              padding: 0 20px;
            }
          }

          .dot img {
            width: 20px;
            height: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }
      .image {
        width: 100% !important;
        height: 265px !important;
        border-radius: 2em 3em 0 3em !important;

        .rectangle2 {
          border-radius: 0 3em 0 3em !important;
        }
        .rectangle3 {
          border-radius: 0 3em 0 3em !important;
        }
      }
    }

    .section-two {
      padding: 140px 10px 20px;
      .about {
        h1 {
          font-size: 26px;
          line-height: 40px;
        }
        h2 {
          font-size: 18px;

          line-height: 28px;
        }
      }
      .skills {
        position: relative !important;
        width: 100% !important;
        height: 400px !important;
        right: 50%;
        transform: scale(0.7) translateX(65%);

        h1 {
          font-size: 22px;
        }
      }
    }

    .section-three {
      gap: 70px;
      .education {
        .edu {
          img {
            height: 80px;
          }
          .edu-title {
            h1 {
              font-size: 20px;
            }
            h2 {
              font-size: 19px;
            }
            h3 {
              font-size: 18px;
            }
          }
        }
      }
      .experience {
        .exp {
          img {
            height: 80px;
          }
          .exp-title {
            h1 {
              font-size: 20px;
            }
            h2 {
              font-size: 19px;
            }
            h3 {
              font-size: 18px;
            }
          }
        }
      }
    }

    .section-four {
      padding: 120px 10px 0;
      margin-bottom: 220px !important;
      gap: 10px;

      #pill {
        transform: rotate(190deg);
        top: -70px;
        right: -100px;
        width: 160px;
      }

      #cubo {
        bottom: -150px;
        left: -120px;
        width: 200px;
      }

      .title {
        gap: 15px;
      }

      .portfolio {
        font-size: 32px;
      }

      .all-project {
        font-size: 16px;
      }

      .projects .project {
        padding: 15px;
      }
    }
  }

  footer .container {
    max-width: 350px !important;

    .LetsWork {
      top: -80px;
      right: 12px;
      font-size: 24px;
      line-height: 34px;
    }

    .footer-top .contacts .contact {
      svg {
        font-size: 19px;
      }

      span {
        font-size: 18px;
      }
    }

    .footer-bottom {
      .social div a svg {
        font-size: 22px !important;
      }

      .ooo p {
        font-size: 18px;
      }
    }
  }
}

@media (min-height: 880px) and (min-width: 901px) {
  main #home #torus {
    top: 690px;
  }
}

@media (min-width: 901px) {
  main #home {
    height: calc(100vh - 80px);
    max-height: 920px;
  }
}
